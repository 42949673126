import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import { FieldArray, Form, Formik } from "formik";
import { useStyles } from "../../prevencion/rgrl/styles/formStyles";
import FormikActions from "../formik/FormikActions";
import RequeridoLabel from "../labels/RequeridoLabel";
import CustomStepper from "../stepper/CustomStepper";
import { validatePlanillaTable } from "./defaultPlanillaValidation";
import FilaPlanilla from "./planillaColumns/FilaPlanilla";
import TableTitle from "./TableTitle";

const initialValues = {
  idItemFormularioArt: "",
  secuencia: "",
  pregunta: "",
  resultado: "0",
  seleccionOpcion: "", // Agregamos este campo para el Autocomplete
};

const PlanillaTable = ({
  handleSubmit,
  data,
  tituloGrupo,
  contador,
  totalGrupos,
  handleGoBack,
  fromRgrl,
  capitalizeTitle = true,
  validationSchema = validatePlanillaTable,
  columns,
}) => {
  const classes = useStyles();

  return (
    <>
      <TableTitle
        title={capitalizeTitle
          ? `${tituloGrupo.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
            letter.toUpperCase()
          )}`
          : tituloGrupo
        }
      />
      <Formik
        initialValues={{
          opciones: data.reduce((opciones, sustancia, index) => {
            opciones[index] = { ...initialValues, ...sustancia };
            return opciones;
          }, []),
        }}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validationSchema={validationSchema}
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form>
            <FieldArray name="opciones" validateOnChange={false}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell key={column.id}>{column.label}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.opciones.map((row, rowIndex) => {
                      return (
                        <TableRow key={rowIndex}>

                          {columns.map((column) => (
                            <TableCell>
                              <FilaPlanilla
                                columnId={column.id}
                                rowValues={row}
                                index={rowIndex}
                                handleChange={handleChange}
                                values={values}
                                initialValues={initialValues}
                                setFieldValue={setFieldValue}
                              />
                            </TableCell>
                          ))}
                        </TableRow >
                      );
                    })}
                  </TableBody >
                </Table >
                <CustomStepper contador={contador} maxGrupos={totalGrupos} />
                <div className={classes.planillaTableButtonContainer}>
                  {contador === totalGrupos - 1 && fromRgrl && (
                    <RequeridoLabel text="Al enviar el formulario no podrá modificar sus respuestas" />
                  )}
                  <FormikActions
                    submit={
                      contador === totalGrupos - 1
                        ? "Enviar formulario"
                        : "Grupo siguiente"
                    }
                    handleCancel={
                      contador !== 0 ? () => handleGoBack(values) : null
                    }
                    cancel={contador !== 0 ? "Grupo anterior" : null}
                  />
                </div>
              </TableContainer >
            </FieldArray >
          </Form >
        )}
      </Formik >
    </>
  );
};

export default PlanillaTable;
