import { Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  formularioCompletado,
  getSustanciasPlanillaA,
  postGrupoSustanciasA,
} from "../../../../redux/actions/rgrl";
import CircularLoading from "../../../commons/loading/CircularLoading";
import FechaPresentacionLabel from "../../../commons/planillas/FechaPresentacionLabel";
import PlanillaTable from "../../../commons/planillas/PlanillaTable";
import { useStyles } from "../styles/formStyles";

const PlanillaAContainer = ({ nextForm }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const completed = useSelector(
    (state) => state.rgrl.postRgrl.formulariosCompletados.planillaA
  );
  const sustancias = useSelector((state) => state.rgrl.sustanciasA);
  const sustanciasPost = useSelector((state) => state.rgrl.postRgrl.planillaA);

  const [contador, setContador] = useState(0);
  const [grupoActual, setGrupoActual] = useState(null);

  //numero x grupo establecido
  const preguntasPorGrupo = 10;

  const loading = !grupoActual || sustancias.loading;

  // hace falta redondear porque si la cantidad total de preguntas es impar
  // la division da como resultado un numero decimal
  const totalGrupos = Math.ceil(
    sustancias.data[0]?.itemsFormularioArt.length / preguntasPorGrupo
  );
  const tituloGrupo = sustancias.data[0]?.nombre.toLowerCase();

  useEffect(() => {
    dispatch(getSustanciasPlanillaA());
  }, []);

  useEffect(() => {
    if (sustancias.data?.length && !sustanciasPost.length) {
      setGrupoActual(
        sustancias.data[0].itemsFormularioArt.slice(
          contador * preguntasPorGrupo,
          contador * preguntasPorGrupo + preguntasPorGrupo
        )
      );
    }
  }, [sustancias.data]);

  useEffect(() => {
    if (sustanciasPost.length) {
      if (completed) {
        toast.warn("La planilla A ya ha sido completada.");
        nextForm("PlanillaC");
      } else if (sustanciasPost.length <= contador * preguntasPorGrupo) {
        setGrupoActual(
          sustancias.data[0].itemsFormularioArt.slice(
            contador * preguntasPorGrupo,
            contador * preguntasPorGrupo + preguntasPorGrupo
          )
        );
      } else {
        setGrupoActual(
          sustanciasPost.slice(
            contador * preguntasPorGrupo,
            contador * preguntasPorGrupo + preguntasPorGrupo
          )
        );
      }
    }
  }, [contador]);

  const handleSubmit = (values) => {
    dispatch(postGrupoSustanciasA(values.opciones));
    if (
      contador >=
      Math.ceil(
        sustancias.data[0].itemsFormularioArt.length / preguntasPorGrupo
      ) -
      1
    ) {
      dispatch(formularioCompletado("planillaA"));
      nextForm("PlanillaC");
    } else {
      setContador((contador) => contador + 1);
    }
  };

  const handleGoBack = (values) => {
    setContador((contador) => contador - 1);
    dispatch(postGrupoSustanciasA(values.opciones));
  };

  return (
    <>
      <FechaPresentacionLabel />
      <Paper>
        {!loading ? (
          <PlanillaTable
            fromRgrl
            data={grupoActual}
            tituloGrupo={tituloGrupo}
            totalGrupos={totalGrupos}
            contador={contador}
            handleSubmit={handleSubmit}
            handleGoBack={handleGoBack}
            columns={[
              { id: `pregunta`, label: "Sustancia" },
              { id: "si", label: "Sí" },
              { id: "no", label: "No" },
            ]}
          />
        ) : (
          <div className={classes.loadingContainer}>
            <CircularLoading />
          </div>
        )}
      </Paper>
    </>
  );
};

export default PlanillaAContainer;
