import { Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPreguntasTipoDeObra,
  postTipoDeObraForm,
} from "../../../../redux/actions/obra";
import CircularLoading from "../../../commons/loading/CircularLoading";
import FechaPresentacionLabel from "../../../commons/planillas/FechaPresentacionLabel";
import PlanillaTable from "../../../commons/planillas/PlanillaTable";
import { useStyles } from "../styles/formStyle";

const TipoDeObraContainer = ({ nextForm }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const preguntas = useSelector((state) => state.obra.tipoDeObra);
  const tipoObraPost = useSelector(
    (state) => state.obra.postNuevaObra.tipoDeObra
  );

  const [contador, setContador] = useState(0);
  const [grupoActual, setGrupoActual] = useState(null);

  const totalGrupos = preguntas.data.length;
  const loading = !grupoActual || preguntas.loading;
  const tituloGrupo = grupoActual?.nombre;

  useEffect(() => {
    dispatch(getPreguntasTipoDeObra());
  }, [dispatch]);

  useEffect(() => {
    if (preguntas.data?.length) {
      setGrupoActual({
        ...preguntas.data[contador],
        itemsFormularioArt:
          tipoObraPost[contador]?.itemsFormularioArt ||
          preguntas.data[contador].itemsFormularioArt,
      });
    }
  }, [preguntas.data, contador]);

  const handleSubmit = (values) => {
    dispatch(
      postTipoDeObraForm({
        itemsFormularioArt: values.opciones,
        idGrupoItemFormularioArt: grupoActual.idGrupoItemFormularioArt,
        nombre: grupoActual.nombre,
        secuencia: grupoActual.secuencia,
      })
    );

    if (contador >= totalGrupos - 1) {
      nextForm("Actividad");
    } else {
      setContador((prev) => prev + 1);
    }
  };

  const handleGoBack = (values) => {
    dispatch(
      postTipoDeObraForm({
        itemsFormularioArt: values.opciones,
        idGrupoItemFormularioArt: grupoActual.idGrupoItemFormularioArt,
        nombre: grupoActual.nombre,
        secuencia: grupoActual.secuencia,
      })
    );
    setContador((prev) => prev - 1);
  };

  return (
    <>
      <FechaPresentacionLabel />
      <Paper>
        {!loading ? (
          <PlanillaTable
            data={grupoActual.itemsFormularioArt}
            tituloGrupo={tituloGrupo}
            totalGrupos={totalGrupos}
            contador={contador}
            handleSubmit={handleSubmit}
            handleGoBack={handleGoBack}
            capitalizeTitle={false}

            columns={[
              { id: `pregunta`, label: "" },
              { id: "si", label: "Sí" },
              { id: "no", label: "No" },
            ]}
          />
        ) : (
          <div className={classes.loadingContainer}>
            <CircularLoading />
          </div>
        )}
      </Paper>
    </>
  );
};

export default TipoDeObraContainer;
