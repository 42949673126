import { Navigate, Route, Routes } from "react-router-dom";
import { getFuncionalidadesParaPerfilUsuario } from "../components/commons/perfilUsuario/PerfilUsuarioEnum";
import CuentaCorrienteContainer from "../components/cuentaCorriente/CuentaCorrienteContainer";
import EmpresaContainer from "../components/empresa/EmpresaContainer";
import ExamenesPeriodicosContainer from "../components/examenesPeriodicos/ExamenesPeriodicosContainer";
import NuevaObraContainer from "../components/obras/nuevaObra/NuevaObraContainer";
import ABMNominaContainer from "../components/personal/ABMnomina/ABMNominaContainer";
import CertificadoCoberturaContainer from "../components/personal/certificadoCobertura/CertificadoCoberturaContainer";
import CoberturaViajeAlExteriorContainer from "../components/personal/coberturaViajeAlExterior/CoberturaViajeAlExteriorContainer";
import NominaMasiva from "../components/personal/nominaMasiva/NominaMasiva";
import EstablecimientosContainer from "../components/prevencion/establecimientos/EstablecimientosContainer";
import DetalleEstablecimientoContainer from "../components/prevencion/establecimientos/estadoEstablecimiento/DetalleEstablecimientoContainer";
import ImportarNTEContainer from "../components/prevencion/nte/ImportarNTEContainer";
import RGRLContainer from "../components/prevencion/rgrl/RGRLContainer";
import ReintegrosContainer from "../components/reintegros/ReintegrosContainer";
import NuevaDenunciaContainer from "../components/siniestros/nuevaDenuncia/NuevaDenunciaContainer";
import SiniestroContainer from "../components/siniestros/siniestralidad/SiniestroContainer";

const AuthRoutes = ({ data }) => {
  const rutasDisponibles = getFuncionalidadesParaPerfilUsuario(
    data?.menuVisible
  );

  return (
    <Routes>
      {rutasDisponibles.Empresa && (
        <Route key="empresas" path="/" element={<EmpresaContainer />} />
      )}

      {rutasDisponibles.CuentaCorriente && (
        <Route
          key="cuentaCorriente"
          path="/cuentaCorriente"
          element={<CuentaCorrienteContainer />}
        />
      )}

      {rutasDisponibles.ABMNominas && (
        <Route
          key="ABMnominas"
          path="/personal/ABMnominas"
          element={<ABMNominaContainer />}
        />
      )}

      {rutasDisponibles.NominaMasiva && (
        <Route
          key="personal"
          path="/personal/nominaMasiva"
          element={<NominaMasiva />}
        />
      )}

      {rutasDisponibles.CertificadoDeCobertura && (
        <Route
          key="certificadoCobertura"
          path="/personal/certificadoDeCobertura"
          element={<CertificadoCoberturaContainer />}
        />
      )}

      {rutasDisponibles.CoberturaViajeAlExterior && (
        <Route
          key="coberturaViajeAlExterior"
          path="/personal/coberturaViajeAlExterior"
          element={<CoberturaViajeAlExteriorContainer />}
        />
      )}

      {rutasDisponibles.Establecimientos && (
        <>
          <Route
            key="prevencionEstablecimientos"
            path="/prevencion/establecimientos"
            element={<EstablecimientosContainer />}
          />
          <Route
            key="detalleEstadoEstablecimiento"
            path="/prevencion/establecimientos/*"
            element={<DetalleEstablecimientoContainer />}
          />
        </>
      )}

      {rutasDisponibles.RGRL && (
        <Route key="RGRL" path="/prevencion/rgrl" element={<RGRLContainer />} />
      )}

      {rutasDisponibles.ImportarNTE && (
        <Route
          key="importarNTE"
          path="/prevencion/NTE"
          element={<ImportarNTEContainer />}
        />
      )}

      {rutasDisponibles.Resolucion81 && (
        <Route
          key="prevencionResolucion81"
          path="/prevencion/resolucion-81"
          Component={() => {
            window.location.replace(
              CONFIG.resolucion81Url
            );
            return null;
          }}
        />
      )}

      {rutasDisponibles.NuevaObra && (
        <Route
          key="nuevaObra"
          path="/obras/nuevaObra"
          element={<NuevaObraContainer />}
        />
      )}

      {rutasDisponibles.Siniestralidad && (
        <Route
          key="siniestralidad"
          path="/siniestros/siniestralidad"
          element={<SiniestroContainer />}
        />
      )}

      {rutasDisponibles.NuevaDenuncia && (
        <Route
          key="nuevaDenuncia"
          path="/siniestros/nuevaDenuncia"
          element={<NuevaDenunciaContainer />}
        />
      )}

      {rutasDisponibles.ExamenesMedicosPeriodicos && (
        <Route
          key="examenesPeriodicos"
          path="/examenesPeriodicos"
          element={<ExamenesPeriodicosContainer />}
        />
      )}

      {rutasDisponibles.Reintegros && (
        <Route
          key="reintegros"
          path="/reintegros"
          element={<ReintegrosContainer />}
        />
      )}

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default AuthRoutes;
