import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import React from "react";

const useStyles = makeStyles({
  headerLabelChildrenContainer: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  headerSecondItem: {
    paddingLeft: 30,
  },
  headerThirdItem: {
    paddingLeft: 20,
  },
});

const NuevaObraLabel = ({ identificacion }) => {
  const classes = useStyles();

  return (
    Object.keys(identificacion).length !== 0 && (
      <>
        <div className={classes.headerLabelChildrenContainer}>
          <Typography className={classes.headerSecondItem}>
            <strong>{identificacion.denominacionObra}</strong>
          </Typography>
        </div>
        <Typography className={classes.headerThirdItem}>
          {`${format(identificacion.fechaInicioObra, "dd/MM/yyyy")} - ${format(
            identificacion.fechaFinObra,
            "dd/MM/yyyy"
          )}`}
        </Typography>
      </>
    )
  );
};

export default NuevaObraLabel;
