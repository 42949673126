import ReactDOM from "react-dom";
import packagejson from "../package.json";
import App from "./App";

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register(CONFIG.path + "/service-worker.js")
    .then((registration) => {
      // Enviar un mensaje al Service Worker para verificar la versión
      const messageChannel = new MessageChannel();
      messageChannel.port1.onmessage = (event) => {
        if (event.data.action === "updateAvailable") {
          // Activar el nuevo Service Worker
          registration.waiting.postMessage({ action: "skipWaiting" });
        }
      };
      registration.active.postMessage(
        { action: "checkVersion", version: packagejson.version },
        [messageChannel.port2]
      );
    });
}

ReactDOM.render(<App />, document.getElementById("root"));
