import { FormControlLabel, MenuItem, Radio, RadioGroup } from "@material-ui/core";
import FormikSelect from "../../formik/FormikSelect";

const FilaPlanilla = ({ columnId, rowValues, index, handleChange, values, initialValues, setFieldValue }) => {
    switch (columnId) {
        case "pregunta": {
            return rowValues.pregunta
        }
        case "si":
            return (
                <RadioGroup
                    name={`opciones.${index}.resultado`}
                    value="1"
                    onChange={handleChange}
                >
                    <FormControlLabel
                        value="1"
                        control={
                            <Radio
                                checked={
                                    values.opciones[index]
                                        ?.resultado === "1"
                                }
                            />
                        }
                    />
                </RadioGroup >
            )

        case "no":
            return (
                <RadioGroup
                    name={`opciones.${index}.resultado`}
                    value="0"
                    onChange={(v) => {
                        handleChange(v);
                        setFieldValue(`opciones.${index}.seleccionOpcion`, initialValues.seleccionOpcion);
                    }}
                >
                    <FormControlLabel
                        value="0"
                        control={
                            <Radio
                                checked={
                                    values.opciones[index]
                                        ?.resultado === "0"
                                }
                            />
                        }
                    />
                </RadioGroup>
            )
        case "umbral":
            return rowValues.umbral;
        case "seleccionOpcion":
            return (
                <>
                    {
                        rowValues.opciones?.length && (
                            <FormikSelect
                                disabled={
                                    values.opciones[index]?.resultado ===
                                    "0"
                                }
                                name={`opciones.${index}.seleccionOpcion`}
                                label="Construcción"
                                variant="standard"
                                fullWidth
                            >
                                {rowValues.opciones.map(({ id, nombre }) => (
                                    <MenuItem value={id} key={id}>
                                        {nombre}
                                    </MenuItem>
                                ))}
                            </FormikSelect>
                        )
                    }
                </>)
        default:
            return null;

    }
}

export default FilaPlanilla;